import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { useWindowSize } from "../../hooks/window-size";
import Share from "../share/share"
import "./Btn.scss";
import { scrollToElement } from "../common/site/functions"

const ButtonGroup = (props) => {
  const [windowWidth] = useWindowSize();

  const isMobile = windowWidth < 768

  return (
    <section className="fix-btn details-fixed">
    <Container className="career-bottom-btns">
      <Row>
        <Col md={12} className="d-lg-none">
          <div className="btn-groups">
            <button className="btn btn-secondary">
              <Share text={isMobile ? "Share" : "Share this Job"}/>
            </button>
            <Link to={`/job-apply?jobtitle=${props.jobname}/`} className="btn">
              <i className="icon-mail"></i>
              <span>{isMobile ? "Apply" : "Apply for this Job"}</span>
            </Link>     
          </div>
        </Col>
      </Row>
    </Container>
    </section>
  );
};

export default ButtonGroup;
