import React from "react"
import SEO from "../components/Seo/seo"
import { Container, Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import ContactCard from "../components/ContactSidebar/ContactSidebarNoStaff"
import Header from "../components/Header/Header"
import CareersDetail from "../components/CareersDetail/CareersDetail"
import Footer from "../components/Footer/Footer"
import Btn from "../components/BottomBtn/BtnCareer";
import BrandLogo from "../images/logo.svg";

const CareersDetailPage = ({ data }) => {
  const career = data.glstrapi.career


  return (
    <div className="careers-detail-page">
      <SEO
        title={career.Meta_Title ? career.Meta_Title : career.Job_Opening}
        description={career.Meta_Description}
        image={BrandLogo}
      />
      <Header alt/>
      <CareersDetail career={career}/>
      <Footer popularsearch= "Sale_Search"/>
      <Btn jobname={career.URL}/>
    </div>
  )
}

export default CareersDetailPage

export const pageQuery = graphql`
  query GetCareer($articleId: ID!) {
    glstrapi {
      career(id: $articleId, publicationState: LIVE) {
        URL
        Job_Opening
        id
        About_Role
        Department
        Location
        Meta_Description
        Meta_Title
        Salary
        Select_Contact_Staff {
          Designation
          URL
          Email
          id
          Phone
          Name
          imagetransforms
          languages
          Image {
            url
            url_sharp {
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  width: 180
                  quality: 80
                  layout: FIXED
                  transformOptions: { cropFocus: CENTER, fit: COVER }
                )
              }
            }
          }
        }
      }
    }
  }
`
